import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SiteConfigManager, useApplicationState } from '@apollo/core';
import { COMPONENT_TYPES } from '@apollo/routing';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { cloneDeep, filter, forEach, forOwn, map } from 'lodash';
import { RaceMarketsMultilegsTabs, RaceTypeAll, RaceTypesMap } from '@apollo/core/src/constants';
import { RacingActions, useRacingState } from '@apollo/core/src/state/racing/racing';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import cx from 'classnames';
import RaceFilterListItem from '../RaceFilters/RaceFilterListItem';
import { RacingIcon } from '../../../shared/components/Racing/RacingIcon';
import Label from '../../../shared/components/I18n/Label';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import MainBody from '../../../shared/components/MainBody/MainBody';
import VenuePageDesktop from './VenuePageDesktop';
import VenuePageMobile from './VenuePageMobile';
import NextToJumpPage from '../NextToJumpPage';
import { generateRaceLink } from '../../../core/utils';
import RacingBreadcrumbs from '../RacingBreadcrumbs';
import { RACE_COUNTRIES, RACE_STATUS } from '../../../core/constants';
import { RacingMarketTypeName } from '../../../shared/components/Racing/RacingMarketTypeName';
import { nextToJumpPath } from '../RacingPathHandler';
import Loader from '../../../shared/components/Loader';

const VenuesPage = () => {
  const t = useTranslate();
  const { push: historyPush, location } = useHistory();
  const { layout } = useApplicationState();
  const [isResults, setIsResults] = useState(false);
  const raceFilterRef = useRef();
  const resultsFilterRef = useRef();

  const desktopNextToJumpPage = SiteConfigManager.getConfig(
    'config.config.racing.desktopNextToJumpPage',
  );

  const showRaceResults = SiteConfigManager.getConfig('config.config.racing.showRaceResults');

  const [racingState, racingDispatcher] = useRacingState();
  const {
    dates,
    // venues,
    meetings,
    raceDate,
    raceType,
    raceCountry,
    raceNumber,
    activeMarketTypeId,
    loading,
  } = racingState;

  const racingPath = useMemo(
    () => SiteConfigManager.getExtraConfig(`pathList.${COMPONENT_TYPES.RACING}`),
    [],
  );

  const raceTypeMap = useMemo(
    () => Object.values(RaceTypesMap).reduce((acc, type) => {
      acc[type.typeName] = !!(
        raceType
          && (raceType.toUpperCase() === RaceTypeAll.typeName?.toUpperCase()
            || raceType.toUpperCase() === type.typeName?.toUpperCase())
      );
      return acc;
    }, {}),
    [raceType],
  );

  const [selectedRaceType, setSelectedRaceType] = useState(raceTypeMap);
  const [selectedCountries, setSelectedCountries] = useState({
    [RACE_COUNTRIES.local]: true,
    [RACE_COUNTRIES.international]: true,
  });
  const [date, setDate] = useState(moment(raceDate));
  const [focused, setFocused] = useState(false);

  const resultDates = useMemo(() => {
    if (!dates[0]?.date || !showRaceResults) {
      return [];
    }

    const resultDates = [
      { ...dates[0] },
      { name: t('Yesterday'), date: moment(dates[0].date).add(-1, 'd').format('YYYY-MM-DD') },
    ];

    for (let d = 2; d <= 7; d++) {
      const dt = moment(dates[0].date).add(-d, 'd');
      resultDates.push({ name: dt.format('ddd Do'), date: dt.format('YYYY-MM-DD') });
    }
    return resultDates.reverse();
  }, [t, dates, showRaceResults]);

  useEffect(() => {
    setSelectedRaceType(raceTypeMap);
  }, [raceTypeMap]);

  useEffect(() => {
    setDate(raceDate ? moment(raceDate) : null);

    if (!showRaceResults) {
      return;
    }

    const dayDiff = moment(raceDate).diff(dates[0]?.date, 'days');
    if (dayDiff < 0) {
      setIsResults(true);
    } else if (dayDiff > 0) {
      setIsResults(false);
    }

    const { current } = raceFilterRef;
    if (current) {
      current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, [raceDate, dates]);

  useEffect(() => {
    window.setTimeout(() => {
      const { current } = resultsFilterRef;
      if (current && isResults) {
        current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }
    }, 333); // time to render
  }, [isResults, raceDate]);

  const venuesByDate = useMemo(
    () => meetings
      .filter((m) => m.date === raceDate)
      .map((m) => ({
        ...m.venue,
        races: m.races,
        multilegsMarkets: m.multilegsMarkets,
      })),
    [meetings],
  );

  const venuesByTypeMap = useMemo(() => {
    const venuesByTypeMap = cloneDeep(RaceTypesMap);

    forEach(venuesByDate, (v) => {
      if (venuesByTypeMap[v.type] == null) {
        return;
      }
      if (venuesByTypeMap[v.type].venues == null) {
        venuesByTypeMap[v.type].venues = [];
      }

      if (raceCountry && v.country !== raceCountry) {
        return;
      }

      venuesByTypeMap[v.type].venues.push(v);
    });

    forOwn(venuesByTypeMap, (value, key) => {
      venuesByTypeMap[key].venues = filter(venuesByTypeMap[key].venues, (v) => {
        if (raceCountry && v.country !== raceCountry) {
          return false;
        }
        if (
          !selectedCountries[RACE_COUNTRIES.local]
          && (v.country === 'NZL' || v.country === 'AUS')
        ) {
          return false;
        }
        if (
          !selectedCountries[RACE_COUNTRIES.international]
          && !(v.country === 'NZL' || v.country === 'AUS')
        ) {
          return false;
        }

        return true;
      });
    });

    return venuesByTypeMap;
  }, [raceCountry, meetings, selectedCountries]);

  const venuesByType = useMemo(() => {
    const venuesByType = [];
    forEach(Object.values(venuesByTypeMap), (rt) => {
      if (rt?.venues?.length && selectedRaceType?.[rt?.typeName]) {
        const { venues } = rt;
        let maxNumberOfRaces = 0;

        const groupedVenues = venues?.reduce(
          (acc, ven) => {
            const venue = !isResults || ven?.races?.some((r) => r.status !== RACE_STATUS.OPEN)
              ? {
                ...ven,
                races: ven.races.filter((r) => !isResults || r.status !== RACE_STATUS.OPEN),
              }
              : null;
            if (!venue) {
              return acc;
            }
            if (venue.races?.length > maxNumberOfRaces) {
              maxNumberOfRaces = venue.races.length;
            }
            if (ven.country === 'AUS' || ven.country === 'NZL') {
              acc.local.push(venue);
            } else {
              acc.international.push(venue);
            }

            return acc;
          },
          {
            international: [],
            local: [],
          },
        );
        if (groupedVenues.international?.length || groupedVenues.local?.length) {
          groupedVenues.international.sort((a, b) => a.venueOrder - b.venueOrder);
          groupedVenues.local.sort((a, b) => a.venueOrder - b.venueOrder);

          venuesByType.push({
            ...rt,
            venues: groupedVenues,
            maxNumberOfRaces,
          });
        }
      }
    });
    return venuesByType;
  }, [selectedRaceType, venuesByTypeMap, isResults]);

  const onRaceTypeClick = (typeName) => {
    if (
      selectedRaceType[typeName]
      && Object.values(selectedRaceType).filter((val) => val).length <= 1
    ) {
      // can't unselect the last one
      return;
    }
    setSelectedRaceType({ ...selectedRaceType, [typeName]: !selectedRaceType[typeName] });
  };
  const onCountryClick = (country) => {
    setSelectedCountries({ ...selectedCountries, [country]: !selectedCountries[country] });
  };

  const onSelectedMarketType = (marketType) => {
    racingDispatcher({
      type: RacingActions.RACING_STATE_UPDATE,
      payload: {
        activeMarketTypeId: activeMarketTypeId === marketType ? null : marketType,
      },
    });
  };

  const onDateChange = (date) => {
    if (date) {
      setDate(date);
      const toUrl = generateRaceLink({
        raceDate: date.format('YYYY-MM-DD'),
        raceType,
        raceCountry,
      });
      historyPush(toUrl);
    }
  };

  const isNextToJump = location.pathname === `${racingPath}${nextToJumpPath}`;

  useEffect(() => {
    if (isNextToJump) {
      setIsResults(false);
    }
  }, [isNextToJump]);

  const raceTypes = useMemo(
    () => Object.values(RaceTypesMap)
      .filter((raceType) => selectedRaceType[raceType.typeName])
      .map((raceType) => raceType.id),
    [selectedRaceType],
  );

  return (
    <MainBody className='main__body--venues-page'>
      <RacingBreadcrumbs />
      <div className='sport-page__header sport-page__header--racing'>
        <h1 className='sport-name'>Racing</h1>
      </div>
      <div className='venue_page_header_container'>
        <div className='venue_page_header'>
          {/* <Scrollbars className='scroll__container'> */}

          {dates[0] && showRaceResults ? (
            <RaceFilterListItem
              id={dates[0]?.date}
              name={t('results')}
              raceDate={dates[0]?.date}
              raceType={raceType}
              active={isResults}
              refProps={isResults ? raceFilterRef : null}
              className='venue_page_header__item'
              onClick={() => {
                setIsResults(true);
              }}
            />
          ) : null}

          {layout.mobileDevice || desktopNextToJumpPage ? ( // next to jump link
            <div
              className={`venue_page_header__item ${isNextToJump ? 'active' : ''}`}
              ref={isNextToJump ? raceFilterRef : null}
            >
              <Link to={`${racingPath}${nextToJumpPath}`}>
                <Label message='next_to_jump' className='next-jump-bar__title' />
              </Link>
            </div>
          ) : null}
          {map(dates, (dt, i) => (
            <RaceFilterListItem
              key={dt.date + i}
              id={dt.date}
              name={dt.name}
              raceDate={dt.date}
              raceType={raceType}
              active={dt.date === raceDate && !isNextToJump && !isResults}
              refProps={dt.date === raceDate && !isNextToJump && !isResults ? raceFilterRef : null}
              className='venue_page_header__item'
              onClick={() => setIsResults(false)}
            />
          ))}
          {/* </Scrollbars> */}
          {!layout.mobileDevice && !showRaceResults ? (
            <div className='position-right venue-cal'>
              <SingleDatePicker
                placeholder={t('date')}
                block={false}
                noBorder
                displayFormat='DD-MM-YY'
                onDateChange={onDateChange}
                numberOfMonths={1}
                date={date} // momentPropTypes.momentObj or null
                focused={focused} // PropTypes.bool
                onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
                id='your_unique_id' // PropTypes.string.isRequired,
                isOutsideRange={() => false}
              />
              <span className='icon AkIcon-calendar-icon' />
            </div>
          ) : null}
        </div>
        {isResults && showRaceResults ? (
          <div className='venue_page_header'>
            {resultDates?.map((dt) => (
              <RaceFilterListItem
                key={dt.date}
                id={dt.date}
                name={dt.name}
                raceDate={dt.date}
                raceType={raceType}
                active={dt.date === raceDate && !isNextToJump}
                refProps={dt.date === raceDate && !isNextToJump ? resultsFilterRef : null}
                className='venue_page_header__item'
              />
            ))}
          </div>
        ) : null}
      </div>
      <div className='venue_page__race_type__filter'>
        <div className='filter__wrapper'>
          <Scrollbars className='scroll__container'>
            {map(Object.keys(RaceTypesMap), (raceTypes) => (
              <div
                key={raceTypes}
                className='filter-item'
                onClick={() => onRaceTypeClick(RaceTypesMap?.[raceTypes]?.typeName)}
              >
                <input
                  type='checkbox'
                  checked={selectedRaceType?.[RaceTypesMap?.[raceTypes]?.typeName]}
                  readOnly
                />
                <label className=''>
                  <RacingIcon
                    id={RaceTypesMap?.[raceTypes]?.id}
                    name={RaceTypesMap?.[raceTypes]?.typeName}
                  />
                </label>
              </div>
            ))}
            <div
              className='filter-item filter-item__country filter-item__country--first'
              onClick={() => selectedCountries[RACE_COUNTRIES.international]
                && onCountryClick(RACE_COUNTRIES.local)}
            >
              <input type='checkbox' checked={selectedCountries[RACE_COUNTRIES.local]} readOnly />
              <label className=''>
                <Label message='race local' />
              </label>
            </div>
            <div
              className='filter-item filter-item__country'
              onClick={() => selectedCountries[RACE_COUNTRIES.local]
                && onCountryClick(RACE_COUNTRIES.international)}
            >
              <input
                type='checkbox'
                checked={selectedCountries[RACE_COUNTRIES.international]}
                readOnly
              />
              <label className=''>
                <Label message='race international' />
              </label>
            </div>
            {layout.mobileDevice && !showRaceResults ? (
              <div className='mobile-date-picker venue-cal position-right'>
                <SingleDatePicker
                  placeholder={t('date')}
                  block={false}
                  noBorder
                  displayFormat='DD-MM-YY'
                  onDateChange={onDateChange}
                  numberOfMonths={1}
                  readOnly
                  small
                  withPortal
                  date={date} // momentPropTypes.momentObj or null
                  focused={focused} // PropTypes.bool
                  onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
                  id='mobile-data-picker' // PropTypes.string.isRequired,
                  isOutsideRange={() => false}
                />
                <span className='icon AkIcon-calendar-icon' />
              </div>
            ) : null}
            {!layout.mobileDevice && !isNextToJump ? (
              <div className='filter-item market-tabs'>
                {Object.keys(RaceMarketsMultilegsTabs)
                  .filter((tab) => meetings.some((meeting) => meeting?.multilegsMarkets?.some((leg) => leg.type === tab)))
                  .map((tab) => (
                    <div className='tab' key={tab}>
                      <div
                        className={cx('tab_button', { active: activeMarketTypeId === tab })}
                        onClick={() => onSelectedMarketType(tab)}
                      >
                        <span className='market_name'>
                          <RacingMarketTypeName marketType={tab} />
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            ) : null}
          </Scrollbars>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : !isNextToJump
        && (Object.values(selectedRaceType).every((i) => !i) || venuesByType.length === 0) ? (
          <div className='no_venues'>
            <Label message='No Venues' />
          </div>
        ) : (
          <div className='venue__content__container'>
            {isNextToJump ? (
              <NextToJumpPage raceTypes={raceTypes} selectedCountries={selectedCountries} />
            ) : (
              map(venuesByType, (rt) => (
                <React.Fragment key={rt.id}>
                  {layout.mobileDevice === true ? (
                    <VenuePageMobile
                      raceType={rt}
                      raceDate={raceDate}
                      raceNumber={raceNumber}
                      selectedCountries={selectedCountries}
                      activeMarketTypeId={activeMarketTypeId}
                    />
                  ) : (
                    <VenuePageDesktop
                      raceType={rt}
                      raceDate={raceDate}
                      selectedCountries={selectedCountries}
                      activeMarketTypeId={activeMarketTypeId}
                    />
                  )}
                </React.Fragment>
              ))
            )}
          </div>
        )}
    </MainBody>
  );
};
export default VenuesPage;
