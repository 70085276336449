/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { BonusApi, Bus, logger, useApplicationState, useSettings } from '@apollo/core';
import { BONUS_TYPE } from '@apollo/core/src/constants';
import { isEmpty } from 'lodash';
import { bonusStatuses } from '../../core/constants';
import { useJumpingCoinAnimation } from '../../features/Animations/JumpingCoinAnimation';

export const BonusActions = {
  STATE_UPDATE: 'STATE_UPDATE',
  STATE_RESET: 'STATE_RESET',
  RESET_ERROR: 'RESET_ERROR',
  ADD_LOADER: 'ADD_LOADER',
  REMOVE_LOADER: 'REMOVE_LOADER',
  AVAILABLE_BONUSES_UPDATE: 'AVAILABLE_BONUSES_UPDATE',
  ACTIVE_BONUSES_UPDATE: 'ACTIVE_BONUSES_UPDATE',
  ACTIVE_BET_RETURNS_UPDATE: 'ACTIVE_BET_RETURNS_UPDATE',
  ACTIVE_PROMO_ODDS_UPDATE: 'ACTIVE_PROMO_ODDS_UPDATE',
  ACTIVE_ODDS_BOOSTS_UPDATE: 'ACTIVE_ODDS_BOOSTS_UPDATE',
  ACTIVE_FREE_BETS_UPDATE: 'ACTIVE_FREE_BETS_UPDATE',
  BONUSES_UPDATE: 'BONUSES_UPDATE',
  BONUS_STATUS: 'BONUS_STATUS',
  BONUS_ACTIVATE: 'BONUS_ACTIVATE',
  BONUS_DEACTIVATE: 'BONUS_DEACTIVATE',
};

const VALID_DEPOSIT_BONUS_TYPES = [
  BONUS_TYPE.DEPOSIT,
  BONUS_TYPE.RELOAD_DEPOSIT,
  BONUS_TYPE.FREEBET_DEPOSIT,

  // The API in this provider only has access to Deposit Bonuses and Bet Returns
  // todo: integrate other bonus types. Remove from account/wallet
  // BONUS_TYPE.BET_RETURN,
  // BONUS_TYPE.FREEBET,
  // BONUS_TYPE.ODDS_BOOST,
  // BONUS_TYPE.BET_RETURN,
];

const bonusInitialState = {
  loading: false,
  activeLoaders: 0,
  error: null,

  availableBonuses: [],
  activeBonuses: [],
  activeBetReturns: [],
  activePromoOdds: [],
  activeOddsBoosts: [],
  activeFreeBets: [],
  bonuses: [],

  betReturnsByEventId: {},
  promoOddsByEventId: {},

  // isLoading: true,
};

const createBonusInitialState = (options) => {
  const { ...restOptions } = options;

  return {
    ...bonusInitialState,
    ...restOptions,
  };
};

const addFreeBetToState = (state, data) => {
  if (!data || !data.id) {
    return state;
  }

  let activeFreeBets = [...state.activeFreeBets];
  const idx = state.activeFreeBets.findIndex((br) => br.id === data.id);
  if (idx >= 0) {
    activeFreeBets = [...activeFreeBets.slice(0, idx), data, ...activeFreeBets.slice(idx + 1)];
  } else {
    activeFreeBets = [...activeFreeBets, data];
  }

  return {
    ...state,
    activeFreeBets,
  };
};

const removeFreeBetFromState = (state, data) => {
  if (!data || !data.id) {
    return state;
  }
  return {
    ...state,
    activeFreeBets: state.activeFreeBets.filter((br) => br.id !== data.id),
  };
};

const addOddsBoostToState = (state, data) => {
  if (!data || !data.id) {
    return state;
  }

  let activeOddsBoosts = [...state.activeOddsBoosts];
  const idx = state.activeOddsBoosts.findIndex((br) => br.id === data.id);
  if (idx >= 0) {
    activeOddsBoosts = [
      ...activeOddsBoosts.slice(0, idx),
      data,
      ...activeOddsBoosts.slice(idx + 1),
    ];
  } else {
    activeOddsBoosts = [...activeOddsBoosts, data];
  }

  return {
    ...state,
    activeOddsBoosts,
  };
};

const removeOddsBoostFromState = (state, data) => {
  if (!data || !data.id) {
    return state;
  }
  return {
    ...state,
    activeOddsBoosts: state.activeOddsBoosts.filter((br) => br.id !== data.id),
  };
};

const addBetReturnToState = (state, data) => {
  if (!data || !data.id) {
    return state;
  }

  let activeBetReturns = [...state.activeBetReturns];
  const idx = state.activeBetReturns.findIndex((br) => br.id === data.id);
  if (idx >= 0) {
    activeBetReturns = [
      ...activeBetReturns.slice(0, idx),
      data,
      ...activeBetReturns.slice(idx + 1),
    ];
  } else {
    activeBetReturns = [...activeBetReturns, data];
  }

  const betReturnsByEventId = data?.betReturn?.sportLimit?.eventIds.reduce(
    (list, id) => ({
      ...list,
      [id]: {
        ...(state.betReturnsByEventId[id] || []),
        [data.id]: data,
      },
    }),
    {},
  );

  return {
    ...state,
    activeBetReturns,
    betReturnsByEventId: {
      ...state.betReturnsByEventId,
      ...betReturnsByEventId,
    },
  };
};

const addPromoOddsToState = (state, data) => {
  if (!data || !data.id) {
    return state;
  }

  let activePromoOdds = [...state.activePromoOdds];
  const idx = state.activePromoOdds.findIndex((br) => br.id === data.id);
  if (idx >= 0) {
    activePromoOdds = [...activePromoOdds.slice(0, idx), data, ...activePromoOdds.slice(idx + 1)];
  } else {
    activePromoOdds = [...activePromoOdds, data];
  }

  const promoOddsByEventId = data?.oddsBoost?.sportLimit?.eventIds.reduce(
    (list, id) => ({
      ...list,
      [id]: {
        ...(state.promoOddsByEventId[id] || []),
        [data.id]: data,
      },
    }),
    {},
  );

  return {
    ...state,
    activePromoOdds,
    promoOddsByEventId: {
      ...state.promoOddsByEventId,
      ...promoOddsByEventId,
    },
  };
};

const removeBetReturnFromState = (state, data) => {
  if (!data || !data.id) {
    return state;
  }

  const betReturnsByEventId = Object.keys(state.betReturnsByEventId).reduce((list, id) => {
    if (!state.betReturnsByEventId[id][data.id]) {
      return {
        ...list,
        [id]: state.betReturnsByEventId[id],
      };
    }

    const newData = {
      ...(state.betReturnsByEventId[id] || {}),
      [data.id]: null,
    };
    delete newData[data.id];

    if (!isEmpty(newData)) {
      return {
        ...list,
        [id]: newData,
      };
    }

    return list;
  }, {});

  return {
    ...state,
    activeBetReturns: state.activeBetReturns.filter((br) => br.id !== data.id),
    betReturnsByEventId,
  };
};

const removePromoOddsFromState = (state, data) => {
  if (!data || !data.id) {
    return state;
  }

  const promoOddsByEventId = Object.keys(state.promoOddsByEventId).reduce((list, id) => {
    if (!state.promoOddsByEventId[id][data.id]) {
      return {
        ...list,
        [id]: state.promoOddsByEventId[id],
      };
    }

    const newData = {
      ...(state.promoOddsByEventId[id] || {}),
      [data.id]: null,
    };
    delete newData[data.id];

    if (!isEmpty(newData)) {
      return {
        ...list,
        [id]: newData,
      };
    }

    return list;
  }, {});

  return {
    ...state,
    activePromoOdds: state.activePromoOdds.filter((br) => br.id !== data.id),
    promoOddsByEventId,
  };
};

const bonusReducer = (state, action) => {
  logger.debug('[bonus state] dispatching action', action);

  switch (action.type) {
    case BonusActions.STATE_UPDATE: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }

    case BonusActions.STATE_RESET: {
      return {
        ...bonusInitialState,
        ...(action.payload || {}),
      };
    }

    case BonusActions.RESET_ERROR: {
      return {
        ...state,
        ...(action.payload || {}),
        error: null,
      };
    }

    case BonusActions.ADD_LOADER: {
      const activeLoaders = state.activeLoaders + 1;
      return {
        ...state,
        ...(action.payload || {}),
        activeLoaders,
        loading: true,
      };
    }

    case BonusActions.REMOVE_LOADER: {
      let activeLoaders = state.activeLoaders - 1;
      if (activeLoaders < 0) {
        activeLoaders = 0;
      }
      const loading = activeLoaders !== 0;
      return {
        ...state,
        ...(action.payload || {}),
        activeLoaders,
        loading,
      };
    }

    case BonusActions.AVAILABLE_BONUSES_UPDATE: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }

    case BonusActions.ACTIVE_BONUSES_UPDATE: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }

    case BonusActions.ACTIVE_BET_RETURNS_UPDATE: {
      return (action.payload.activeBetReturns || []).reduce(
        (newState, data) => addBetReturnToState(newState, data),
        state,
      );
    }

    case BonusActions.ACTIVE_PROMO_ODDS_UPDATE: {
      return (action.payload.activePromoOdds || []).reduce(
        (newState, data) => addPromoOddsToState(newState, data),
        state,
      );
    }

    case BonusActions.ACTIVE_ODDS_BOOSTS_UPDATE: {
      return (action.payload.activeOddsBoosts || []).reduce(
        (newState, data) => addOddsBoostToState(newState, data),
        state,
      );
    }

    case BonusActions.ACTIVE_FREE_BETS_UPDATE: {
      return (action.payload.activeFreeBets || []).reduce(
        (newState, data) => addFreeBetToState(newState, data),
        state,
      );
    }

    case BonusActions.BONUSES_UPDATE: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }

    case BonusActions.BONUS_ACTIVATE: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }

    case BonusActions.BONUS_STATUS: {
      const { bonusType } = action.payload;
      switch (bonusType) {
        case BONUS_TYPE.BET_RETURN: {
          const betReturn = {
            ...(state.activeBetReturns?.find((br) => br.id === action.payload.id) || {}),
            ...action.payload,
          };
          if (action.payload.status !== bonusStatuses.ACTIVATED) {
            return removeBetReturnFromState(state, betReturn);
          }
          return addBetReturnToState(state, betReturn);
        }

        case BONUS_TYPE.ODDS_BOOST: {
          const oddsBoost = {
            ...(state.activeOddsBoosts?.find((br) => br.id === action.payload.id) || {}),
            ...action.payload,
          };
          if (action.payload.status !== bonusStatuses.ACTIVATED) {
            return removeOddsBoostFromState(state, oddsBoost);
          }
          return addOddsBoostToState(state, oddsBoost);
        }

        case BONUS_TYPE.PROMO_ODDS: {
          const promoOdds = {
            ...(state.activePromoOdds?.find((br) => br.id === action.payload.id) || {}),
            ...action.payload,
          };
          if (action.payload.status !== bonusStatuses.ACTIVATED) {
            return removePromoOddsFromState(state, promoOdds);
          }
          return addPromoOddsToState(state, promoOdds);
        }

        case BONUS_TYPE.FREEBET: {
          const freeBet = {
            ...(state.activeFreeBets?.find((br) => br.id === action.payload.id) || {}),
            ...action.payload,
          };
          if (action.payload.status !== bonusStatuses.ACTIVATED) {
            return removeFreeBetFromState(state, freeBet);
          }
          return addFreeBetToState(state, freeBet);
        }
        default:
      }
      return state;
    }

    case BonusActions.BONUS_DEACTIVATE: {
      const { bonusType } = action.payload;
      switch (bonusType) {
        case BONUS_TYPE.BET_RETURN:
          {
            const betReturn = state.activeBetReturns?.find((br) => br.id === action.payload.id);
            if (betReturn) {
              return removeBetReturnFromState(state, betReturn);
            }
          }
          break;

        case BONUS_TYPE.ODDS_BOOST:
          {
            const oddsBoost = state.activeOddsBoosts?.find((br) => br.id === action.payload.id);
            if (oddsBoost) {
              return removeOddsBoostFromState(state, oddsBoost);
            }
          }
          break;

        case BONUS_TYPE.PROMO_ODDS:
          {
            const promoOdds = state.activePromoOdds?.find((br) => br.id === action.payload.id);
            if (promoOdds) {
              return removePromoOddsFromState(state, promoOdds);
            }
          }
          break;

        case BONUS_TYPE.FREEBET:
          {
            const freeBet = state.activeFreeBets?.find((br) => br.id === action.payload.id);
            if (freeBet) {
              return removeFreeBetFromState(state, freeBet);
            }
          }
          break;
        default:
          break;
      }

      const availableBonuses = state.availableBonuses?.filter((b) => b.id !== action.payload.id);
      const activeBonuses = state.activeBonuses?.filter((b) => b.id !== action.payload.id);
      const bonuses = state.bonuses?.filter((b) => b.id !== action.payload.id);
      return {
        ...state,
        availableBonuses,
        activeBonuses,
        bonuses,
      };
    }

    default:
      return state;
  }
};

export const createBonusState = (options) => {
  const { lang, country } = useSettings();
  const { connection, authenticated, wallet } = useApplicationState();
  const { runJumpingCoinAnimation } = useJumpingCoinAnimation();

  const [state, dispatcher] = React.useReducer(
    bonusReducer,
    options || {},
    createBonusInitialState,
  );

  const fetchActiveBonuses = async (language = null, ac = null) => {
    dispatcher({
      type: BonusActions.STATE_RESET,
    });
    dispatcher({
      type: BonusActions.ADD_LOADER,
    });
    await BonusApi.getActiveBonuses({
      language,
      bonusTypes: VALID_DEPOSIT_BONUS_TYPES,
      signal: ac?.signal,
    })
      .then((data) => {
        const { depositBonuses, betReturns, freeBets, oddsBoosts } = data;

        dispatcher({
          type: BonusActions.ACTIVE_BONUSES_UPDATE,
          payload: {
            activeBonuses: depositBonuses || [], // ?.filter((b) => b.status === bonusStatuses.ACTIVATED),
          },
        });
        dispatcher({
          type: BonusActions.ACTIVE_BET_RETURNS_UPDATE,
          payload: {
            activeBetReturns: betReturns || [], // ?.filter((b) => b.status === bonusStatuses.ACTIVATED),
          },
        });
        dispatcher({
          type: BonusActions.ACTIVE_FREE_BETS_UPDATE,
          payload: {
            activeFreeBets: freeBets || [], // ?.filter((b) => b.status === bonusStatuses.ACTIVATED),
          },
        });
        dispatcher({
          type: BonusActions.ACTIVE_ODDS_BOOSTS_UPDATE,
          payload: {
            activeOddsBoosts: (oddsBoosts || []).filter(
              (b) => b.bonusType === 'ODDS_BOOST' && b.status === bonusStatuses.ACTIVATED,
            ),
          },
        });
        dispatcher({
          type: BonusActions.ACTIVE_PROMO_ODDS_UPDATE,
          payload: {
            activePromoOdds: (oddsBoosts || []).filter(
              (b) => b.bonusType === 'PROMO_ODDS' && b.status === bonusStatuses.ACTIVATED,
            ),
          },
        });
        dispatcher({
          type: BonusActions.REMOVE_LOADER,
          payload: {
            error: null,
          },
        });
      })
      .catch((error) => {
        logger.debug('[bonus state] active bonuses error', error);
        dispatcher({
          type: BonusActions.REMOVE_LOADER,
          payload: {
            error,
          },
        });
      });
  };

  // const fetchAvailableBonuses = async (currency, language, country, ac = null) => {
  //   dispatcher({
  //     type: BonusActions.ADD_LOADER,
  //   });
  //   await BonusApi.getAvailableBonuses({
  //     currency,
  //     language,
  //     country,
  //     signal: ac?.signal,
  //   })
  //     .then((data) => {
  //       dispatcher({
  //         type: BonusActions.AVAILABLE_BONUSES_UPDATE,
  //         payload: {
  //           availableBonuses: data.bonuses,
  //         },
  //       });
  //       dispatcher({
  //         type: BonusActions.REMOVE_LOADER,
  //         payload: {
  //           error: null,
  //         },
  //       });
  //     })
  //     .catch((error) => {
  //       logger.debug('[bonus state] available bonuses error', error);
  //       dispatcher({
  //         type: BonusActions.REMOVE_LOADER,
  //         payload: {
  //           error,
  //         },
  //       });
  //     });
  // };

  const bonusStatusHandler = (payload) => {
    logger.debug('[bonus state] status handler', payload?.data);

    dispatcher({
      type: BonusActions.BONUS_STATUS,
      payload: {
        ...(payload?.data || {}),
        error: null,
      },
    });
  };

  const bonusEligibleHandler = (payload) => {
    logger.debug('[bonus state] eligible handler', payload?.data);

    if (payload?.data?.isEligible) {
      runJumpingCoinAnimation(3000, {
        prefix: payload?.data?.bonusType,
        icon: payload?.data?.bonusType,
      });
    }
  };

  const bonusActivateHandler = (payload) => {
    logger.debug('[bonus state] activate handler', payload?.data);

    runJumpingCoinAnimation(3000, {
      prefix: payload?.data?.bonusType,
    });

    fetchActiveBonuses(lang);
    // dispatcher({
    //   type: BonusActions.BONUS_ACTIVATE,
    //   payload: {
    //     error: null,
    //   },
    // });
  };

  const bonusDeactivateHandler = (payload) => {
    logger.debug('[bonus state] deactivate handler', payload?.data);
    dispatcher({
      type: BonusActions.BONUS_DEACTIVATE,
      payload: payload.data,
    });
  };

  const bonusReloadHandler = (payload) => {
    logger.debug('[bonus state] reload handler', payload?.data);
    fetchActiveBonuses(lang);
  };

  /**
   * Wallet Bonuses (FreeBets, OddsBoost, BetReturns)
   */
  // React.useEffect(() => {
  //   if (!authenticated || !wallet?.bonuses) {
  //     dispatcher({
  //       type: BonusActions.STATE_RESET,
  //     });
  //   } else {
  //     dispatcher({
  //       type: BonusActions.BONUSES_UPDATE,
  //       payload: {
  //         bonuses: wallet.bonuses,
  //         // bonuses: wallet.bonuses.filter((b) => !VALID_DEPOSIT_BONUS_TYPES.includes(b.bonusType)),
  //       },
  //     });
  //   }
  // }, [wallet?.bonuses, authenticated]);

  /**
   * Bonus initialise
   */
  React.useEffect(() => {
    if (!authenticated) {
      return;
    }

    const ac = new AbortController();

    fetchActiveBonuses(
      lang.toUpperCase(), // todo: should be parsed server-side
      ac,
    );

    // fetchAvailableBonuses(
    //   wallet.currency,
    //   lang.toUpperCase(), // todo: should be parsed server-side
    //   country,
    //   ac,
    // );

    Bus.on(Bus.events.profile.bonusStatus, bonusStatusHandler);
    Bus.on(Bus.events.profile.bonusEligible, bonusEligibleHandler);
    Bus.on(Bus.events.profile.bonusActivate, bonusActivateHandler);
    Bus.on(Bus.events.profile.bonusDeactivate, bonusDeactivateHandler);
    Bus.on('profile:bonus_reload', bonusReloadHandler);

    return () => {
      Bus.off(Bus.events.profile.bonusStatus, bonusStatusHandler);
      Bus.off(Bus.events.profile.bonusEligible, bonusEligibleHandler);
      Bus.off(Bus.events.profile.bonusActivate, bonusActivateHandler);
      Bus.off(Bus.events.profile.bonusDeactivate, bonusDeactivateHandler);
      Bus.off('profile:bonus_reload', bonusReloadHandler);
      ac.abort();
    };
  }, [connection.id, lang, country, wallet?.currency]);

  return [state, dispatcher];
};

const BonusContext = React.createContext();

export const BonusProvider = (props) => {
  const { value, children } = props;
  const [state, dispatcher] = createBonusState(value);
  return (
    <BonusContext.Provider value={React.useMemo(() => [state, dispatcher], [state, dispatcher])}>
      {children}
    </BonusContext.Provider>
  );
};

export const useBonusState = () => React.useContext(BonusContext);

const selectState = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state] = useBonusState();
  return state;
};

const selectIsLoading = () => {
  const [state] = useBonusState();
  return !!state.loading;
};

const selectAvailableBonuses = () => {
  const [state] = useBonusState();
  return React.useMemo(() => state.availableBonuses, [state.availableBonuses]);
};

const selectActiveBonuses = () => {
  const [state] = useBonusState();
  return React.useMemo(() => state.activeBonuses, [state.activeBonuses]);
};

const selectActiveBetReturns = () => {
  const [state] = useBonusState();
  return React.useMemo(() => state.activeBetReturns, [state.activeBetReturns]);
};

const selectActiveDepositBonuses = () => {
  const [state] = useBonusState();

  return React.useMemo(
    () => (state.activeBonuses || []).filter((b) => VALID_DEPOSIT_BONUS_TYPES.includes(b.bonus?.bonusType)),
    [state.activeBonuses],
  );
};

const hasActiveDepositBonus = () => {
  const [state] = useBonusState();
  return React.useMemo(
    () => (state.activeBonuses || []).some((b) => VALID_DEPOSIT_BONUS_TYPES.includes(b.bonus?.bonusType)),
    [state.activeBonuses],
  );
};

const getBetReturnsByEventId = (id) => {
  const [state] = useBonusState();
  return state.betReturnsByEventId?.[id];
};

const getPromoOddsByEventId = (id) => {
  const [state] = useBonusState();
  return state.promoOddsByEventId?.[id];
};

const isDef = (v) => v !== undefined && v !== null;

// hide internal structure of API-supplied promo odds from the consuming code
const getPromoOddsParams = (promoOdds) => (isDef(promoOdds)
  ? {
    getName() {
      return promoOdds?.oddsBoost?.name;
    },
    getFinalOdds() {
      return promoOdds?.oddsBoost?.finalOdds;
    },
  }
  : null);

const getPromoOddsByEventIdAndOutcomeId = ({ eventId, outcomeId }) => {
  const eventPromoOdds = getPromoOddsByEventId(eventId);
  if (!eventPromoOdds) {
    return [];
  }
  return Object.values(eventPromoOdds)
    .filter((promoOdds) => promoOdds.oddsBoost?.sportLimit?.outcomeIds?.includes(String(outcomeId)))
    .map(getPromoOddsParams);
};

const hasActiveBetReturn = (id) => !!getBetReturnsByEventId(id);

const hasActivePromoOdds = (id) => !!getPromoOddsByEventId(id);

// eslint-disable-next-line max-len
const outcomeHasActivePromoOdds = ({ eventId, outcomeId }) => getPromoOddsByEventIdAndOutcomeId({ eventId, outcomeId }).length > 0;

const selectAllBonuses = () => {
  const [state] = useBonusState();
  return React.useMemo(
    () => [
      ...state.activeBonuses,
      ...state.activeBetReturns,
      ...state.activeOddsBoosts,
      ...state.activeFreeBets,
      ...state.activePromoOdds,
    ].filter((b) => b && b.status === bonusStatuses.ACTIVATED),
    [
      state.activeBonuses,
      state.activeBetReturns,
      state.activeOddsBoosts,
      state.activeFreeBets,
      state.activePromoOdds,
    ],
  );
  // return React.useMemo(() => state.bonuses, [state.bonuses]);
};

export const BonusSelector = {
  selectState,
  selectIsLoading,
  selectAvailableBonuses,
  selectActiveBonuses,
  selectActiveDepositBonuses,
  hasActiveDepositBonus,
  hasActiveBetReturn,
  hasActivePromoOdds,
  outcomeHasActivePromoOdds,
  getBetReturnsByEventId,
  getPromoOddsByEventId,
  getPromoOddsByEventIdAndOutcomeId,
  selectAllBonuses,
};
